@import './assets/scss2/variables';
@import '~bootstrap/scss/bootstrap.scss';

// //Import Light mode
@import './assets/scss2/style.scss';
@import './assets/scss2/custom.scss';

@import './assets/css/materialdesignicons.min.css';

// Import Dark RTL mode
// @import './assets/scss2/style-dark-rtl.scss';

// Import RTL mode
// @import './assets/scss2/style-rtl.scss';

// Import Dark Mode
// @import './assets/scss2/style-dark.scss';

@import '~@glidejs/glide/src/assets/sass/glide.core.scss';
@import '~@glidejs/glide/src/assets/sass/glide.theme.scss';

// HelpCenter page icons
// @import url('https://unicons.iconscout.com/release/v2.1.11/css/unicons.css');

.container {
  padding-left: 32px;
  padding-right: 32px;
}

.post-content {
  img {
    max-width: 100% !important;
  }
}

.transition-group {
  position: relative;
}

.route-section {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}

.fade-enter {
  opacity: 0;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-in;
}
